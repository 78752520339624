<template>
  <form-wizard-alt />
</template>

<script>
// eslint-disable-next-line import/extensions
import FormWizardAlt from '@/views/forms/form-wizard/FormWizardAlt'

export default {

  name: 'OfficeData',
  components: {
    FormWizardAlt,
  },
  data() {
    return {

      user: [],

    }
  },

  mounted() {
    const x = localStorage.getItem('userData')
    this.user = JSON.parse(x)
    // console.log(x)
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Zatwierdź"
      next-button-text="Dalej"
      back-button-text="Wstecz"
      @on-complete="formSubmitted"
    >

      <!-- account details tab -->
      <tab-content
        title="Prosimy uzupełnić dane kontaktowe"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Dane kontaktowe
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nazwa"
                label-for="fullname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nazwa"
                  rules="required"
                >
                  <b-form-input
                    id="fullname"
                    v-model="fullname"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="NIP"
                label-for="nip"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NIP"
                  rules="required"
                >
                  <b-form-input
                    id="nip"
                    v-model="nip"
                    type="text"
                    :disabled="true"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="REGON"
                label-for="regon"
              >
                <validation-provider
                  #default="{ errors }"
                  name="REGON"
                  rules="required"
                >
                  <b-form-input
                    id="regon"
                    v-model="regon"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Adres Rejestrowy"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Adres Rejestrowy
              </h5>
              <small class="text-muted">Podaj adres rejestrowy</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Województwo"
                label-for="register-state"
              >
                <validation-provider
                  #default="{ errors }"
                  name="województwo"
                  rules="required"
                >
                  <b-form-input
                    id="register-state"
                    v-model="registerState"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Powiat"
                label-for="register-district"
              >
                <validation-provider
                  #default="{ errors }"
                  name="powiat"
                >
                  <b-form-input
                    id="register-district"
                    v-model="registerDistrict"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Gmina"
                label-for="register-community"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gmina"
                >
                  <b-form-input
                    id="register-community"
                    v-model="registerCommunity"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  label="Kod pocztowy"
                  label-for="register-zip-code"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="register-zip-code"
                    v-model="registerZipCode"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Miejscowość"
              >
                <b-form-group
                  label="Miejscowość"
                  label-for="register-city"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="register-city"
                    v-model="registerCity"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Ulica"
                rules="required"
              >
                <b-form-group
                  label="Ulica"
                  label-for="register-street"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="register-street"
                    v-model="registerStreet"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Adres do korespondencji"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Adres do korespondencji
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Województwo"
                label-for="contact-state"
              >
                <validation-provider
                  #default="{ errors }"
                  name="województwo"
                  rules="required"
                >
                  <b-form-input
                    id="contact-state"
                    v-model="contactState"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Powiat"
                label-for="contact-district"
              >
                <validation-provider
                  #default="{ errors }"
                  name="powiat"
                >
                  <b-form-input
                    id="contact-district"
                    v-model="contactDistrict"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Gmina"
                label-for="contact-community"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gmina"
                >
                  <b-form-input
                    id="contact-community"
                    v-model="contactCommunity"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  label="Kod pocztowy"
                  label-for="contact-zip-code"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="contact-zip-code"
                    v-model="contactZipCode"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Miejscowośc"
                rules="required"
              >
                <b-form-group
                  label="Miejscowość"
                  label-for="contact-city"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="contact-city"
                    v-model="contactCity"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Ulica"
                rules="required"
              >
                <b-form-group
                  label="Ulica"
                  label-for="contact-street"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="contact-street"
                    v-model="contactStreet"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="Umowa"
        :before-change="validationFormContract"
      >
        <validation-observer
          ref="contractRules"
          tag="form"
        >
          <b-row>
            <b-col>
              <h5 class="mb-0">
                Treść umowy
              </h5>
              <small class="text-muted">Zapoznaj się z treścią umowy</small>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae vestibulum est. Aenean blandit sapien vel dignissim rhoncus. Integer eu placerat lacus. Quisque nec felis sem. Fusce hendrerit ante sit amet mauris suscipit, a mattis leo porttitor. Nunc at purus augue. Phasellus non fermentum erat.
                Maecenas congue nisl magna, ac tempus nisi interdum nec. Pellentesque feugiat eu lacus ac convallis. Integer et semper lacus. Donec pharetra mi eu ultrices accumsan. Curabitur pulvinar enim massa, ut ultrices ipsum molestie id. Donec finibus ante quis nulla viverra, nec tincidunt augue elementum. Sed a mauris in felis mattis finibus nec quis dolor. Suspendisse at bibendum erat, eget vestibulum odio. Morbi ultrices orci imperdiet tellus blandit, at scelerisque urna venenatis. Etiam sagittis elit non fermentum rhoncus. Nullam iaculis, nisl id malesuada dapibus, eros lacus viverra lacus, a efficitur quam lorem sed sem. Maecenas congue eget velit ultricies commodo.
                Cras fermentum, augue eu ultricies commodo, purus ligula fermentum urna, nec porttitor enim nunc et ligula. Sed cursus mi eget leo porta, eu volutpat sem tincidunt. Donec eu magna lacus. Vestibulum vel ipsum nec ante sagittis congue sit amet ac dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc nec aliquet magna. Nullam sed interdum purus, in ullamcorper metus.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Warunki umowy"
                rules="required:true"
              >
                <b-form-checkbox
                  id="contract"
                  v-model="contractAccepted"
                  name="contract_accepted"
                >Akceptuję warunki umowy</b-form-checkbox>
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Warunki umowy"
                rules="required:true"
              >
                <b-form-checkbox
                  id="privacy-policy"
                  v-model="privacyPolicyAccepted"
                  name="privacy_policy_accepted"
                >Akceptuję zasady polityki prywatności</b-form-checkbox>
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import axios from 'axios'
import Swal from 'sweetalert2'
import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userData: [],
      fullname: '',
      cellPhone: '',
      nip: '',
      regon: '',
      registerState: '',
      registerDistrict: '',
      registerZipCode: '',
      registerCity: '',
      registerCommunity: '',
      registerStreet: '',
      contactState: '',
      contactDistrict: '',
      contactZipCode: '',
      contactCity: '',
      contactCommunity: '',
      contactStreet: '',
      contractAccepted: false,
      privacyPolicyAccepted: false,
      required,
      email,
      codeIcon,
    }
  },
  mounted() {
    this.getOfficeData()
  },
  methods: {
    formSubmitted() {
      if (!this.contractAccepted || !this.privacyPolicyAccepted) {
        Swal.fire('Prosimy zapoznać się z umową i polityką prywatności oraz zaakceptować warunki', '', 'info')
      } else {
        this.updateOfficeData()
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormContract() {
      return new Promise((resolve, reject) => {
        this.$refs.contractRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getOfficeData() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$route.query.token}`,
        },
      }
      axios.get(`${process.env.VUE_APP_API_URL}Business`, config).then(response => {
        this.fullname = response.data[0].Title
        this.nip = response.data[0].NIP
        this.regon = response.data[0].Regon
        this.registerCity = response.data[0].City
        this.registerDistrict = response.data[0].District
        this.registerCommunity = response.data[0].Community
        this.registerState = response.data[0].Province
        this.registerStreet = response.data[0].Street
        this.registerZipCode = response.data[0].ZipCode
        this.contactCity = response.data[0].City
        this.contactDistrict = response.data[0].District
        this.contactCommunity = response.data[0].Community
        this.contactState = response.data[0].Province
        this.contactStreet = response.data[0].Street
        this.contactZipCode = response.data[0].ZipCode
      }).catch(error => {
        console.log(error)
      })
    },
    updateOfficeData() {
      const putData = {
        Title: this.fullname,
        Regon: this.regon,
        NIP: this.nip,
        Province: this.registerState,
        District: this.registerDistrict,
        City: this.registerCity,
        Street: this.registerStreet,
        Community: this.registerCommunity,
        ZipCode: this.registerZipCode,
        ContactProvince: this.contactState,
        ContactDistrict: this.contactDistrict,
        ContactCity: this.contactCity,
        ContactStreet: this.contactStreet,
        ContactCommunity: this.contactCommunity,
        ContactZipCode: this.contactZipCode,
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.$route.query.token}`,
        },
      }
      axios.put(`${process.env.VUE_APP_API_URL}Business`, putData, config).then(response => {
        Swal.fire('Zaktualizowano dane biura', '', 'success')
        this.$bvModal.hide('modal-1')
        this.$router.push('/login')
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
  },
}
</script>
